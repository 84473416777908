/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-console */
import {
  Background,
  Controls,
  MiniMap,
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import '@xyflow/react/dist/style.css';
import { ReactFormBuilder } from 'react-form-builder2';
import './global.css';
import { Button } from 'reactstrap';
import Switch from 'src/components/FormFields/Switch';
import classes from './Flow.module.scss';
import FlowRun from './FlowRun/FlowRun';
import NodeInfo from './NodeInfo';
import { edgeTypes, initialEdges } from './edges';
import { initialNodes, nodeTypes } from './nodes';

let id = 1;
const getId = () => `${Math.random()}`;
// var savedNodes //= typeof localStorage !== "undefined" && localStorage.getItem("flow_nodes") && localStorage.getItem("flow_nodes") !== 'undefined' && JSON.parse(localStorage.getItem("flow_nodes"));
// var savedEdges //= typeof localStorage !== "undefined" && localStorage.getItem("flow_edges") && localStorage.getItem("flow_edges") !== 'undefined' && JSON.parse(localStorage.getItem("flow_edges"));
// if (savedEdges && savedEdges.map) {
//   savedEdges.map((e) => { e.type = "button-edge" })
//   console.log('loaded flow')
// }
const AddNodeOnEdgeDrop = () => {
  var savedNodes = undefined; //= typeof localStorage !== "undefined" && localStorage.getItem("flow_nodes") && localStorage.getItem("flow_nodes") !== 'undefined' && JSON.parse(localStorage.getItem("flow_nodes"));
  var savedEdges = undefined; //= typeof localStorage !== "undefined" && localStorage.getItem("flow_edges") && localStorage.getItem("flow_edges") !== 'undefined' && JSON.parse(localStorage.getItem("flow_edges"));

  useEffect(() => {
    if (!savedEdges || !savedNodes) {
      savedNodes =
        typeof localStorage !== 'undefined' &&
        localStorage.getItem('flow_nodes') &&
        localStorage.getItem('flow_nodes') !== 'undefined' &&
        JSON.parse(localStorage.getItem('flow_nodes'));
      savedEdges =
        typeof localStorage !== 'undefined' &&
        localStorage.getItem('flow_edges') &&
        localStorage.getItem('flow_edges') !== 'undefined' &&
        JSON.parse(localStorage.getItem('flow_edges'));
      if (!savedNodes) savedNodes = initialNodes;
      if (!savedEdges) savedEdges = initialEdges;

      if (savedEdges && savedEdges.map)
        savedEdges.map((e) => {
          e.type = 'button-edge';
        });
      setNodes(savedNodes);
      setEdges(savedEdges);
      console.log('loaded flow');
    }
  }, []);

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [focusedNode, setFocusedNode] = useState(null);
  const [counter, setCounter] = useState(0);
  const [nodes, setNodes, _onNodesChange] = useNodesState(savedNodes);
  const [edges, setEdges, _onEdgesChange] = useEdgesState(savedEdges);
  const { screenToFlowPosition } = useReactFlow();
  const [dataset, setDataset] = useState(null);
  const [currentListing, setCurrentListing] = useState(null);

  const [viewMode, setViewMode] = useState('manager');
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const saveState = useCallback(
    (nodes, edges) => {
      localStorage.setItem('flow_nodes', JSON.stringify(nodes));
      localStorage.setItem('flow_edges', JSON.stringify(edges));
      // console.log('saved flow')
    },
    [nodes, edges, counter]
  );

  const datasetSelected = useCallback((params) => {
    setDataset(params);
    // alert(JSON.stringify(params))
    setViewMode('dashboard');
  }, []);

  const listingSelected = useCallback((params) => {
    setCurrentListing(params);
    // alert(JSON.stringify(params))
    setViewMode('preview');
  }, []);

  const onNodesChange = useCallback(
    (params) => {
      // console.log('onNodesChange')
      const addedNode = params.find((n) => n.type === 'add')?.item;
      if (addedNode?.data?.label === '?') setFocusedNode(addedNode);
      saveState(nodes, edges);
      _onNodesChange(params);
    },
    [nodes, edges]
  );

  const onEdgesChange = useCallback(
    (params) => {
      saveState(nodes, edges);
      _onEdgesChange(params);
    },
    [nodes, edges]
  );

  const onConnect = useCallback(
    (params) => {
      // reset the start node on connections
      console.log('!!! onConnect');
      connectingNodeId.current = null;
      setEdges((eds) => addEdge(params, eds));
    },
    [nodes, edges]
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);
  const onNodeClick = useCallback(
    (_, node) => {
      // Using functional state update to ensure you're working with the latest state
      setFocusedNode((prevFocusedNode) => {
        // Log previous and new state if needed for debugging
        console.log('Previous focusedNode:', prevFocusedNode);
        console.log('New node:', node);

        // Update state and return new state
        setCounter(Math.random()); // Or use a better strategy for forcing re-render

        return node;
      });
    },
    [setCounter]
  ); // Only include dependencies that are used within the callback

  // const onNodeUpdate = useCallback((node) => {
  //   // alert('onNodeUpdate ' + JSON.stringify(node))
  //   const updatedNodes = nodes.map(n => {
  //     console.log('cmpare ' + n.id + ' === ' + node?.id + ', ' + node.id)
  //     if (n.id === node.id) {
  //       console.log('replaced ' + node.id)
  //       return node
  //     }
  //     return n;
  //   })
  //   setNodes(updatedNodes)
  //   setFocusedNode(node)
  //   saveState(updatedNodes, edges)
  //   setCounter(Math.random())

  // }, [nodes, edges, counter]);
  const onNodeUpdate = useCallback(
    (node) => {
      // Ensure nodes and edges are correctly updated
      setNodes((prevNodes) => {
        const updatedNodes = prevNodes.map((n) =>
          n.id === node.id ? node : n
        );
        saveState(updatedNodes, edges);
        setFocusedNode(node);
        setCounter(Math.random()); // Or use a different strategy for forcing re-render
        return updatedNodes;
      });
    },
    [edges]
  ); // Ensure dependencies are correct

  // const onConnectEnd = useCallback(
  //   (event) => {
  //     if (!connectingNodeId.current) return;

  //     const targetIsPane = event.target.classList.contains('react-flow__pane');

  //     if (targetIsPane) {
  //       // we need to remove the wrapper bounds, in order to get the correct position
  //       const id = Math.random();
  //       console.log('!!! onConnectEnd ' + id)
  //       const newNode = {
  //         id,
  //         position: screenToFlowPosition({
  //           x: event.clientX,
  //           y: event.clientY,
  //         }),
  //         type: 'Workflow-node',
  //         data: { label: '?' },
  //         origin: [0.5, 0.0],
  //       };

  //       setNodes((nds) => {
  //         if (nds.find(n => n.id === newNode.id)) return nds
  //         var updatedNsd = nds//.concat(newNode)

  //         setEdges((eds) => {
  //           const edgeId = Math.random();
  //           if (eds.find(e => e.id === edgeId)) return eds
  //           console.log('setEdges ' + edgeId)
  //           var updatedEds = eds.concat({ id: edgeId, source: connectingNodeId.current, target: id })
  //           saveState(updatedNsd, updatedEds)
  //           return updatedEds
  //         });
  //         return updatedNsd
  //       });
  //       // setTimeout(() => setFocusedNode(newNode), 200)

  //     }
  //   },
  //   [screenToFlowPosition],
  // );
  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          type: 'Workflow-node',
          data: { label: `?` },
          origin: [0.5, 0.0],
        };

        // @ts-ignore
        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({ id, source: connectingNodeId.current, target: id })
        );
        // @ts-ignore
        setTimeout(() => setFocusedNode(newNode), 200);
      }
    },
    [screenToFlowPosition]
  );
  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              type: 'button-edge',
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );
  function removeReadOnly(ns) {
    if (!ns) return [];
    return ns.map((n) => {
      if (n.data.subType == 'dnode_form' && n.data.task_data) {
        n.data.task_data.map((t) => {
          t.variableKey = t.label?.split('(')[0].trim().replace(/ /g, '_');
          t.readOnly = false;
          // console.log(t)
        });
      }
    });
  }

  return (
    <div className={classes.flowWrapper} ref={reactFlowWrapper}>
      <div className={classes.main}>
        <table
          style={{ right: '30px', position: 'absolute', marginTop: '-40px' }}
          cellPadding={'10px'}
        >
          <tr>
            <td>Preview</td>
            <td>
              <Switch
                checked={viewMode !== 'preview'}
                onChange={(e) => {
                  if (e.target.checked) setViewMode('manager');
                  else setViewMode('preview');
                }}
              />
            </td>
            <td>Edit</td>
          </tr>
        </table>

        <main
          className={classes.content}
          style={
            viewMode == 'manager'
              ? { padding: 0, height: 'calc( 100vh - 150px )' }
              : {}
          }
        >
          {viewMode == 'manager' && (
            <ReactFlow
              nodes={nodes}
              nodeTypes={nodeTypes}
              edges={edges}
              edgeTypes={edgeTypes}
              defaultEdgeOptions={{ type: 'button-edge' }}
              onNodesChange={onNodesChange}
              onNodesDelete={onNodesDelete}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onConnectStart={onConnectStart}
              onConnectEnd={onConnectEnd}
              onNodeClick={onNodeClick}
              onAuxClick={(d) => alert('aux: ')}
              onPaneClick={(d) => setFocusedNode(null)}
              fitView
              fitViewOptions={{ padding: 1 }}
              nodeOrigin={[0.5, 0]}
            >
              <Background />
              <MiniMap />
              <Controls />
            </ReactFlow>
          )}
          {viewMode == 'manager' && (
            <div
              className={
                focusedNode ? 'side-panel side-panel-open' : 'side-panel'
              }
            >
              <NodeInfo
                focusedNode={focusedNode}
                onNodeUpdate={onNodeUpdate}
                nodes={removeReadOnly(nodes)}
                counter={counter}
              />
            </div>
          )}

          {viewMode == 'preview' && (
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <FlowRun
                    nodes={nodes}
                    nodeTypes={nodeTypes}
                    edges={edges}
                    edgeTypes={edgeTypes}
                    currentListing={currentListing}
                  />
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

const Flow = () => (
  <ReactFlowProvider>
    <AddNodeOnEdgeDrop />
  </ReactFlowProvider>
);

export default Flow;
